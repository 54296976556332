<i18n>
{
  "en": {
    "title": "How to log in to use {service}",
    "step1": "1. Select your Authentication provider below: Ubiqu or KPN Grip",
    "step2": "2. You will be directed to the selected Authentication provider to complete the login process."
  },
  "nl": {
    "title": "Inloggen bij {service}",
    "step1": "1. Selecteer uw authenticatieprovider hieronder: Ubiqu of KPN Grip",
    "step2": "2. U wordt doorverwezen naar de geselecteerde authenticatieprovider om het inlogproces te voltooien."
  }
}
</i18n>

<template>

    <v-fade-transition>
        <v-card v-show="loaded" elevation="12" style="top: -100px">

            <v-container class="pa-6">

                <v-row no-gutters>

                    <v-col>

                        <p class="title">{{ $t('title', {service: service.name}) }}</p>



                      <p>{{ $t('step1') }}</p>
                      <p>{{ $t('step2') }}</p>

<!--                        <p>1. Choose one of the Authentication providers listed below.</p>-->
<!--                        <p>2. You will be directed to the selected Authentication Provider's login portal.</p>-->
<!--                        <p>3. Complete the provider's login process.</p>-->
<!--                        <p>4. After successful authentication you will be directed back to {{ service.name }}.</p>-->

                    </v-col>

                </v-row>

                <v-row no-gutters>

                    <v-list-item @click="providerSelected('spin')" class="provider-list-item mb-4">
                        <v-list-item-avatar>
                            <v-img
                                :src="require('../assets/strongpin.svg')"
                                contain
                                aspect-ratio="1"
                            ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-subtitle-1">
                                Ubiqu
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="providerSelected('grip')" class="provider-list-item">
                        <v-list-item-avatar>
                            <v-img
                                :src="require('../assets/grip_black.svg')"
                                contain
                                aspect-ratio="1"
                            ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-subtitle-1">
                                KPN Grip
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-row>

            </v-container>

        </v-card>
    </v-fade-transition>

</template>

<script>

export default {
    name: "AuthProviderCard",
    data: () => ({
        loaded: false,
        service: {
            name: "Strongpin Vault"
        },
    }),

    methods: {

        providerSelected(provider) {
            if (provider === 'spin') {
                window.location.replace(config.SUITE_API_BASE_URL + "auth/?oauth_provider_id=" + config.SPIN_PROVIDER_UUID);
            } else if (provider === 'grip') {
                window.location.replace(config.SUITE_API_BASE_URL + "auth/?oauth_provider_id=" + config.GRIP_PROVIDER_UUID);
            }
        }

    },

    mounted() {
        setTimeout(() => {
            this.loaded = true;
        }, 500);
    }
}
</script>

<style scoped>

.provider-list-item {
    border: 1px solid #bfbfbf;
    border-radius: 2px;
}

.colorTransition {
    transition: 0.4s linear background-color;
}

</style>
