import '@mdi/font/css/materialdesignicons.css'
import 'typeface-roboto/index.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from "vuetify/lib/util/colors"

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: "#2871b4",
        secondary: colors.grey.lighten3,
      },
      dark: {
        primary: "#2871b4",
        secondary: "#303030",
      }
    }
  }
});