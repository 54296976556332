<template>

    <v-app>

        <v-content>

            <v-container style="height: 100%" fluid class="py-0 grey lighten-2">

                <!-- Empty top row with Strongpin color -->
                <v-row class="primary" style="height: 300px">
                </v-row>

                <v-row justify="center" class="grey lighten-2">

                    <v-col cols="12" sm="8" lg="5" xl="4" class="py-0">

                        <onboarding-card></onboarding-card>

                    </v-col>

                </v-row>

            </v-container>

        </v-content>

    </v-app>

</template>

<script>

import OnboardingCard from "@/components/OnboardingCard";

export default {
    name: "App",

    components: {
        OnboardingCard
    },

    data: () => ({

    }),

}
</script>

<style scoped>


</style>